import React from "react"
import { Helmet } from "react-helmet"

import Nav from "../partials/Nav"
import PageSection from "../layout/PageSection"
import SubPageHero from "../partials/SubPageHero"
import PrivacyPolicy from "../partials/PrivacyPolicy"
import Footer from "../partials/Footer"
import CookieBanner from "../partials/CookieBanner"
import "../styles.css"

const PrivacyPolicyPage: React.FC<{}> = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy & Cookies | Run For Heroes</title>
        <meta
          name="description"
          content="We take your data privacy seriously. Our privacy policy explains how we collect, share and use personal information."
        />
        <html lang="en" />
      </Helmet>
      <Nav activePath={"/privacy-policy"} />
      <PageSection backgroundColor={"BLUE"}>
        <SubPageHero heading={"Privacy & Cookies"} />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <PrivacyPolicy />
      </PageSection>
      <PageSection backgroundColor={"GREY"}>
        <Footer />
      </PageSection>
      <CookieBanner />
    </>
  )
}

export default PrivacyPolicyPage
