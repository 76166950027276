import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import BodyText from "../components/BodyText"
import { H2 } from "../components/Heading"

const Root = styled.div`
  max-width: 1340px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
`

const Heading = styled(H2)`
  margin-bottom: 20px;
`

const StyledBodyText = styled(BodyText)`
  margin-bottom: 40px;
`

interface IPrivacyPolicy {
  rest?: object
}

const OurValue: React.FC<IPrivacyPolicy> = ({ ...rest }) => {
  return (
    <Root {...rest}>
      <Heading>Privacy Policy:</Heading>
      <StyledBodyText type={"DARK"}>
        <p>
          <a
            target={"_blank"}
            rel={"noopener"}
            href={
              "https://drive.google.com/file/d/1dR6Ayckw2kckraAF3V5HRrEOD1GOvs6Q/view"
            }
          >
            Please view our privacy policy here.
          </a>
        </p>
      </StyledBodyText>

      <Heading>Cookies:</Heading>
      <StyledBodyText type={"DARK"}>
        <p>This website uses cookies.</p>

        <p>
          We use cookies to personalise content, provide social media features,
          analyse our traffic and to make our site work. Some of these cookies
          are essential to enable core functionality such as security, network
          management, and accessibility; these are called ‘Necessary Cookies.’
          You may disable these by changing your browser settings but this can
          affect how our website will function.
        </p>

        <p>
          We’d also use Google Analytics cookies to help us to improve our
          website by collecting and reporting information on how you use it. The
          cookies collect information in a way that does not directly identify
          anyone. For more detailed information about the cookies we use, see{" "}
          <a
            target={"_blank"}
            rel={"noopener"}
            href={
              "https://drive.google.com/file/d/1dR6Ayckw2kckraAF3V5HRrEOD1GOvs6Q/view"
            }
          >
            our cookies policy
          </a>
          .
        </p>

        <p>
          If you have any questions about our privacy policy or concerns about
          how we use cookies,{" "}
          <Link to={"/contact-us"}>please contact us here</Link>.
        </p>
      </StyledBodyText>
    </Root>
  )
}

export default OurValue
