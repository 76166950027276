import React from "react"
import styled from "styled-components"

import { H1, H2 } from "../components/Heading"
import { LAYOUT, FONT_FAMILIES } from "../constants"

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  flex-direction: column;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    min-height: 480px;
  }
`

const Heading = styled(H1)`
  font-family: ${FONT_FAMILIES.RFH_OUTLINE};
  font-size: 5rem;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    font-size: 13.6rem;
  }
`

const SubHeading = styled(H2)`
  font-family: ${FONT_FAMILIES.MONTSERRAT};
  font-size: 1.8rem;
  margin-top: 20px;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    font-size: 2.2rem;
  }
`

interface ISubPageHero {
  heading?: string
  subHeading?: string
  rest?: object
}

const SubPageHero: React.FC<ISubPageHero> = ({
  heading = "About us",
  subHeading = null,
  ...rest
}) => {
  return (
    <Root {...rest}>
      <Heading color={"WHITE"} alignment={"CENTER"}>
        {heading}
      </Heading>
      {subHeading && (
        <SubHeading color={"WHITE"} alignment={"CENTER"}>
          {subHeading}
        </SubHeading>
      )}
    </Root>
  )
}

export default SubPageHero
